import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { bgBlur } from 'src/theme/css';
import Image from 'src/components/image';

import Logo from '../logo';
import ProgressBar from '../progress-bar';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  ...bgBlur({
    blur: 2,
    opacity: 0.24,
    color: theme.palette.background.default,
  }),
  top: 0,
  zIndex: 9999,
  position: 'fixed',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function SplashScreen({ sx }) {
  return (
    <>
      <ProgressBar />

      <StyledRoot sx={sx}>
        <m.div
          animate={{
            scale: [1, 0.96, 1, 0.96, 1],
            opacity: [1, 0.48, 1, 0.48, 1],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
       <Image
        alt="comingsoon"
        src="https://res.cloudinary.com/dl2ptpjin/image/upload/v1691445187/9D43E1A9-419C-45BC-AE08-58FA6AC89E2A_eymu03.heic"
        sx={{
          my: 3,
          mx: 'auto',
          // maxWidth: 500,
        }}
      />
        </m.div>
      </StyledRoot>

      <Box sx={{ width: 1, height: '100vh' }} />
    </>
  );
}

SplashScreen.propTypes = {
  sx: PropTypes.object,
};
